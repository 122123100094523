import type { AspectRatio } from './responsive-image-types';

function GetHeightForWidthAndAspectRatio(width: number, aspectRatio: AspectRatio) {
  const heightWidthArray = aspectRatio.split(':');
  const denominator = (heightWidthArray?.[0] || 1) as number;
  const multiplier = (heightWidthArray?.[1] || 1) as number;
  const height = (width * multiplier) / denominator;
  return Math.round(height);
}

export { GetHeightForWidthAndAspectRatio };
