import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import cx from 'classnames';
import type { TFunction } from 'i18next';
import { useTranslation } from 'next-i18next';
import { Fragment } from 'react';
import type { GetHotelHomePageQuery } from '../../generated/types';
import { restaurantNameToUrl } from '../../helpers/property-utils';
import type { TTabbedOverviewContent } from './TabbedOverviewContent';
import TabbedOverviewContent from './TabbedOverviewContent';

export type TTab = {
  id: string;
  tabLabel: string;
  hasOnlyOneRestaurant?: boolean;
  tocProps: TTabbedOverviewContent;
} | null;

export type TTabbedOverview = {
  golfOverview?: NonNullable<GetHotelHomePageQuery['hotel']>['golf'];
  restaurantOverview?: NonNullable<GetHotelHomePageQuery['hotel']>['restaurantOverview'];
  spaOverview?: NonNullable<GetHotelHomePageQuery['hotel']>['spa'];
  onSiteRestaurants?: NonNullable<
    NonNullable<GetHotelHomePageQuery['hotel']>['restaurantOverview']
  >['restaurants'];
  traits?: string[];
};

export const onSiteRestaurantTab = (
  t: TFunction<['common', 'dining']>,
  restaurantOverviewData: TTabbedOverview['restaurantOverview'],
  onSiteRestaurants?: TTabbedOverview['onSiteRestaurants']
) => {
  const defaultDiningProps = {
    id: 'dining',
    tabLabel: t('dining:header'),
  };

  if (!onSiteRestaurants?.length) return null;

  if (onSiteRestaurants?.length === 1) {
    const onSiteOutlet = onSiteRestaurants[0];
    const hasDiningMenu = onSiteOutlet?.diningMenus?.length;
    return onSiteOutlet?.description
      ? {
          ...defaultDiningProps,
          hasOnlyOneRestaurant: true,
          tocProps: {
            image: onSiteOutlet?.halfAndHalfImage[0],
            description: onSiteOutlet.description,
            hoursOfOperation: hasDiningMenu ? null : onSiteOutlet?.hoursOfOperation,
            path: `dining/${restaurantNameToUrl(onSiteOutlet?.name || '')}`,
            heading: onSiteOutlet?.name,
            ctaText: hasDiningMenu ? t('dining:viewMenuCtaText') : null,
          },
        }
      : null;
  } else {
    return restaurantOverviewData?.desc
      ? {
          ...defaultDiningProps,
          tocProps: {
            image: restaurantOverviewData.image,
            description: restaurantOverviewData.desc,
            path: 'dining',
            heading: t('dining:header'),
            ctaText: t('dining:exploreDining'),
          },
        }
      : null;
  }
};

const TabbedOverview = ({
  golfOverview,
  restaurantOverview,
  spaOverview,
  onSiteRestaurants,
}: TTabbedOverview) => {
  const { t } = useTranslation(['common', 'dining']);

  const restaurantsTab: TTab = restaurantOverview
    ? onSiteRestaurantTab(t, restaurantOverview, onSiteRestaurants)
    : null;

  const spaTab: TTab =
    spaOverview && spaOverview?.desc
      ? {
          id: 'spa',
          tabLabel: t('common:spa'),
          tocProps: {
            image: spaOverview?.images[0],
            description: spaOverview.desc,
            path: 'spa',
            heading: t('common:spa'),
            ctaText: t('common:spaCtaText'),
          },
        }
      : null;

  const golfTab: TTab =
    golfOverview && golfOverview?.desc
      ? {
          id: 'golf',
          tabLabel: t('common:golf'),
          tocProps: {
            image: golfOverview?.images[0],
            description: golfOverview.desc,
            path: 'golf',
            heading: t('common:golf'),
            ctaText: t('common:golfCtaText'),
          },
        }
      : null;

  const hasOnlyOneRestaurant = restaurantsTab?.hasOnlyOneRestaurant && !spaTab && !golfTab;
  const tabs: TTab[] = [restaurantsTab, spaTab, golfTab].filter((tab) => tab);

  return (
    <>
      {hasOnlyOneRestaurant ? (
        <h2 className="heading-2xl lg:heading-3xl pb-8 pt-10 text-center">
          {t('common:diningAndDrinks')}
        </h2>
      ) : null}
      {tabs?.length ? (
        <Tabs
          className={cx('mx-auto max-w-[1440px] py-12 px-4 md:px-8 lg:px-10', {
            'pt-0': hasOnlyOneRestaurant,
          })}
          changeWithArrow
        >
          <TabList
            data-testid="tabbed-tablist"
            className={cx('text-xl font-bold', {
              hidden: tabs?.length <= 1,
            })}
          >
            {tabs.map((tab, index) => (
              <Fragment key={tab?.tabLabel}>
                <TabListButton data-testid="tabbed-tablistbutton" id={tab?.id}>
                  {tab?.tabLabel}
                </TabListButton>
                {tabs.length - 1 > index && (
                  <div className="bg-bg-inverse inset-y-0 mx-1 my-2 w-px" />
                )}
              </Fragment>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel id={tab?.id} key={tab?.id} data-testid={`tabbed-${tab?.id}-content`}>
                <TabbedOverviewContent {...tab?.tocProps} isHeadlineFont={!hasOnlyOneRestaurant} />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : null}
    </>
  );
};

export default TabbedOverview;
