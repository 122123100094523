import cx from 'classnames';
import Image from 'next/image';
import type { AspectRatio } from '../responsive-image/responsive-image-types';
import { GetHeightForWidthAndAspectRatio } from '../responsive-image/responsive-image';
import { grayBlurDataUrl } from '../../helpers/constants';

type PropertyImage = {
  id?: string;
  alt: string;
  src: string;
  width: number;
  aspectRatio: AspectRatio;
  className?: string;
  onLoad?: () => void;
};

export const getAspectRatioClass = (aspectRatio: AspectRatio) => {
  switch (aspectRatio) {
    case '3:4':
      return 'aspect-[3/4]';
    case '1:1':
      return 'aspect-[1/1]';
    case '3:2':
      return 'aspect-[3/2]';
    case '4:3':
      return 'aspect-[4/3]';
    case '16:9':
      return 'aspect-[16/9]';
    case '21:9':
      return 'aspect-[21/9]';
    case '18:5':
      return 'aspect-[18/5]';
    default:
      return 'aspect-[3/2]'; // default aspect ratio if none specified
  }
};

const NextImage = ({
  id,
  alt,
  src,
  width,
  aspectRatio,
  onLoad,
  className = 'object-cover',
}: PropertyImage) => (
  <Image
    id={id}
    alt={alt}
    src={src}
    width={width}
    height={GetHeightForWidthAndAspectRatio(width, aspectRatio)}
    className={cx(className, getAspectRatioClass(aspectRatio))}
    placeholder="blur"
    blurDataURL={grayBlurDataUrl}
    onLoad={onLoad}
  />
);

export { NextImage as Image };
