import type { Variant } from '@dx-ui/osc-brand-buttons';
import {
  BrandButton as BaseBrandButton,
  BrandLink as BaseBrandLink,
} from '@dx-ui/osc-brand-buttons';
import { usePropertyData } from '../../hooks/use-property-data';

const variantMapper: Record<string, Variant> = {
  GU: 'outline',
  WA: 'solid',
} as const;

function useButtonVariant({ variant }: { variant?: Variant }) {
  const propertyData = usePropertyData();
  const brandCode = propertyData?.brandCode || '';
  return variant || variantMapper[brandCode];
}

export function BrandLink(props: React.ComponentProps<typeof BaseBrandLink>) {
  const variant = useButtonVariant(props);
  return <BaseBrandLink {...props} variant={variant} />;
}

export function BrandButton(props: React.ComponentProps<typeof BaseBrandButton>) {
  const variant = useButtonVariant(props);
  return <BaseBrandButton {...props} variant={variant} />;
}
