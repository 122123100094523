import { useRef } from 'react';
import {
  generateUrl,
  getConsolidatedHoursOfOperation,
  getHotelImageVariant,
} from '../../helpers/property-utils';
import cx from 'classnames';
import { useRect } from '@dx-ui/utilities-use-rect';
import type { HotelImage, HotelImageVariant } from '@dx-ui/gql-types';
import { useRouter } from 'next/router';
import { ConsolidatedHoursSection } from '../restaurant-hours/ConsolidatedHours';
import { Accordion } from '@dx-ui/osc-accordion';
import { Image } from '../image/Image';
import { useTranslation } from 'next-i18next';
import type { GetHotelHomePageQuery } from '../../generated/types';
import { BrandLink } from '../brand-buttons/brand-buttons';

export type TTabbedOverviewContent = {
  image?:
    | (Pick<HotelImage, 'altText'> & {
        variants: Pick<HotelImageVariant, 'size' | 'url'>[];
      })
    | null;
  description?: string;
  hoursOfOperation?:
    | NonNullable<
        NonNullable<GetHotelHomePageQuery['hotel']>['restaurantOverview']
      >['restaurants'][number]['hoursOfOperation']
    | null;
  path?: string;
  heading?: string | null;
  ctaText?: string | null;
  isHeadlineFont?: boolean;
};

const TabbedOverviewContent: React.FC<TTabbedOverviewContent> = function ({
  image,
  description,
  hoursOfOperation,
  path,
  heading,
  ctaText,
  isHeadlineFont,
}) {
  const { locale = 'en' } = useRouter();
  const { t } = useTranslation('dining');
  const { relativeUrl } = generateUrl(useRouter(), path);
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const variant = image && image.variants && getHotelImageVariant(image.variants, rect?.width ?? 0);
  const consolidatedHours =
    !!hoursOfOperation && getConsolidatedHoursOfOperation({ hoursOfOperation, locale });

  return (
    <div className={cx('grid', { 'md:grid-cols-2': !!image })}>
      {variant && variant?.url ? (
        <div className="flex w-full min-w-0 items-center" ref={ref}>
          <Image
            alt={image.altText ?? ''}
            src={variant.url || ''}
            width={rect?.width ?? 0}
            aspectRatio="3:2"
          />
        </div>
      ) : null}
      <div className={cx('flex flex-col justify-center', { 'items-center': !variant })}>
        <div className={cx('md:px-10', { 'max-w-3xl text-center': !variant })}>
          <h3
            className={cx('mt-6 mb-2', {
              'heading-2xl lg:heading-3xl': isHeadlineFont,
              'font-bold text-2xl lg:text-3xl': !isHeadlineFont,
            })}
          >
            {heading}
          </h3>
          <p>{description}</p>
          {consolidatedHours ? (
            <div
              className={cx('flex', {
                'justify-center': !variant,
              })}
            >
              <div className="flex w-full flex-col pt-8">
                <Accordion
                  className={cx('accordion-single text-lg font-bold text-primary no-underline', {
                    'self-start': !!variant,
                    'self-center': !variant,
                  })}
                  items={[
                    {
                      key: 'hours',
                      content: (
                        <section className="pt-4">
                          <ConsolidatedHoursSection
                            consolidatedHours={consolidatedHours}
                            showHeader={false}
                          />
                        </section>
                      ),
                      collapsedButtonLabel: t('hours'),
                      collapsedButtonAccessibleLabel: t('showHoursFor', { heading }),
                      expandedButtonLabel: t('hours'),
                      expandedButtonAccessibleLabel: t('hideHoursFor', { heading }),
                    },
                  ]}
                  data-testid="accordion-button"
                  type="multiple"
                />
              </div>
            </div>
          ) : null}
          {ctaText ? (
            <div className="mt-4 flex w-full">
              <BrandLink url={relativeUrl} label={ctaText} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TabbedOverviewContent;
