import type { ConsolidatedTime } from '../../helpers/property-utils';
import { useTranslation } from 'next-i18next';
import { DaysAndTimeSection } from './DaysAndTimeSection';

type THoursSection = {
  consolidatedHours: ConsolidatedTime[];
  showHeader: boolean;
};

export const ConsolidatedHoursSection = ({ consolidatedHours, showHeader }: THoursSection) => {
  const { t } = useTranslation('dining');

  return consolidatedHours.length ? (
    <div className="divide-border border-border divide-y border-b">
      {showHeader && <h3 className="text-2xl font-bold">{t('restaurantHours.hours')}</h3>}
      {consolidatedHours.map((consolidatedHour) => (
        <div className="flex  space-x-4 py-2" key={consolidatedHour.days[0]?.day || ''}>
          <DaysAndTimeSection daysAndTimes={consolidatedHour} className="font-bold" />
        </div>
      ))}
    </div>
  ) : null;
};
