import { useTranslation } from 'next-i18next';
import type { ConsolidatedTime, LocaleDayNameKey } from '../../helpers/property-utils';
import cx from 'classnames';
import { getLocaleDayName } from '../../helpers/property-utils';
import { useRouter } from 'next/router';
import { useLocale } from '@dx-ui/utilities-dates';

type DaysAndTimeSectionProps = {
  className?: string;
  daysAndTimes: ConsolidatedTime;
};
export const DaysAndTimeSection = ({ daysAndTimes, className }: DaysAndTimeSectionProps) => {
  const { t } = useTranslation('dining');
  const language = useRouter().locale || 'en';
  const locale = useLocale({ language });
  const { days, time } = daysAndTimes;
  const fromDay = getLocaleDayName(locale, days[0]?.day as LocaleDayNameKey);
  const toDay = getLocaleDayName(locale, days[days.length - 1]?.day as LocaleDayNameKey);
  const formattedDays = days.length === 1 ? fromDay : `${fromDay} – ${toDay}`;
  const daysSrReadable = formattedDays.replaceAll('–', t('restaurantHours.through'));
  const timesSrReadable = time.length
    ? time
        .map((timeString) =>
          timeString?.replaceAll('-', t('restaurantHours.through')).replaceAll(/[.:]00/g, '')
        )
        .join(` ${t('restaurantHours.and')} `)
    : t('closed');
  const daysAndTimesSrReadable = `${daysSrReadable} ${timesSrReadable}`;

  return (
    <section className={cx('flex w-full justify-between', className)}>
      <div aria-hidden>
        <p>{formattedDays}</p>
      </div>
      <div aria-hidden>
        {time.length
          ? time.map((timeText) => (
              <p className="text-right font-normal" key={timeText}>
                {timeText.replaceAll('-', '–').replaceAll(/[.:]00/g, '')}
              </p>
            ))
          : t('closed')}
      </div>
      <p className="sr-only">{daysAndTimesSrReadable}</p>
    </section>
  );
};
